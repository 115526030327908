import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { LinearGradient } from 'react-text-gradients';
import logo from './logo.svg';
import exteriorImg from './images/parkside-retreat-exterior.jpg';
import poolImg from './images/parkside-retreat-pool.jpg';
import kitchenImg from './images/parkside-retreat-kitchen-2.jpg';
import livingImg from './images/parkside-retreat-living-room-2.jpg';
import pizzaImg from './images/parkside-retreat-pizza.jpg';
import deskImg from './images/parkside-retreat-dedicated-workspace.jpg';
import deskCloseUpImg from './images/parkside-retreat-dedicated-workspace-close.jpg';
import droneImg from './images/parkside-retreat-clubhouse-drone.jpg';
import heroVideo from './parkside-reatreat-water-slide.mp4';
import mapImg from './images/parkside-retreat-orlando-map.png';
import primaryBathroomImg from './images/parkside-retreat-primary-bathroom.jpg';
import jrSuiteBathroomImg from './images/parkside-reatreat-jr-suite-bathroom.jpg';
import jrSuiteBedroomImg from './images/parkside-retreat-jr-suite-bedroom-2.jpg';
import primarySuiteBedroomImg from './images/parkside-retreat-primary-suite-bedroom-2.jpg';
import shampooImg from './images/parkside-retreat-shampoo.jpeg';
import diningImg from './images/parkside-retreat-dining-2.jpg';
import gamesImg from './images/parkside-retreat-games.jpg';
import bunkRoomImg from './images/parkside-retreat-themed-bunk-bedroom-2.jpg';
import guestBedroomImg from './images/parkside-retreat-guest-bedroom-2.jpg';
import twinBedroom1Img from './images/parkside-retreat-twin-beds-1-2.jpg';
import twinBedroom2Img from './images/parkside-retreat-twin-beds-2-2.jpg';
import babyAmenitiesImg from './images/parkside-retreat-baby-amenities.jpg';
import coffee1 from './images/parkside-retreat-coffee-1.jpg';
import coffee2 from './images/parkside-retreat-coffee-2.jpg';
import coffee3 from './images/parkside-retreat-coffee-3.jpg';
import gameRoomImg from './images/parkside-retreat-game-room-2.jpg';
import laundryImg from './images/parkside-retreat-laundry-room.jpg';
import lazyRiverImg from './images/parkside-retreat-lazy-river-storey-lake.jpg';
import resortPoolImg from './images/parkside-retreat-pool-storey-lake.jpg';
import miniGolfImg from './images/parkside-retreat-mini-golf-storey-lake.jpg';

const CONTENT_MAX_WIDTH = '820px';
const IMG_ASPECT = {
	'1:1': '100%',
	'4:3': '75%',
	'3:4': '125%',
};

const Image = ({ aspect = '1:1', url }) => (
	<div style={{ borderRadius: '6px', paddingTop: IMG_ASPECT[aspect], backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${url})`}} />
);

const Header = () => (
	<div id="booking-widget" style={{ position: 'fixed', top: 0, right: 0, padding: '15px', height: '100%', overflow: 'auto', backgroundColor: '#E7E5DC' }}>
		<img src={logo} alt="Parkside Retreat - Orlando Short Term Rental" style={{ height: '120px', margin: '10px auto 20px', display: 'block' }} />
		<iframe src="https://w.hostexbooking.site/widget/check?host_id=100723&listing_id=103035&enabled=1" frameBorder="0" width="100%" style={{ minHeight: '500px' }} />
	</div>
);

const ContentContainer = ({ children }) => (
	<div style={{ marginRight: '330px' }}>
		{children}
	</div>
);

function App() {
  return (
		<>
			<Header />
			<ContentContainer>
				<Box sx={{ flexGrow: 1 }}>
					<Grid container>
						<Grid item xs={12}>
							<Box sx={{ padding: '25px' }}>
								<Paper elevation={0} sx={{ margin: '0 auto', maxWidth: CONTENT_MAX_WIDTH }}>
									<Grid container justifyContent="space-between" spacing={5}>
										<Grid item xs={6}>
											<Typography variant="h1" sx={{ margin: '10px 0 5px', fontWeight: '700', fontSize: '1.8rem' }}>
											Experience the <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}> magic </LinearGradient> of <br />Orlando at Parkside Retreat
											</Typography>
											<Typography variant="body1" sx={{ marginTop: '5px' }}>
												Nestled in the highly sought after, gated Storey Lake Community, Parkside Retreat is your secret to the perfect Orlando vacation.
											</Typography>
										</Grid>
										<Grid item xs={6}>
											<Image aspect="4:3" url={exteriorImg} />
										</Grid>
									</Grid>
								</Paper>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<Box sx={{ padding: '25px', backgroundColor: '#114A5D' }}>
								<Paper elevation={0} sx={{ color: '#F0F0F0', background: 'none', padding: (t) => t.spacing(2), margin: '0 auto', maxWidth: CONTENT_MAX_WIDTH }}>
									<Grid container justifyContent="space-between" spacing={5}>
										<Grid item xs={6}>
											<Image aspect="4:3" url={mapImg} />
										</Grid>
										<Grid item xs={6}>
											<Typography variant="h3" sx={{ margin: '10px 0 5px', fontWeight: '700', fontSize: '1.8rem' }}>
												Your gateway to Central Florida
											</Typography>
											<Typography variant="body1">
		{`Spend less time driving and more time enjoying! Our home is near Orlando's top attraction and shopping destinations. Reach Disney World Resort in 10 minutes, Universal Orlando in 18, and SeaWorld Orlando in 15 minutes!`}
											</Typography>
										</Grid>
									</Grid>
								</Paper>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<Box sx={{ padding: '25px' }}>
								<Paper elevation={0} sx={{ backgroundColor: '#E7E5DC', padding: (t) => t.spacing(2), margin: '0 auto', maxWidth: CONTENT_MAX_WIDTH }}>
									<Grid container justifyContent="space-between" spacing={5}>
										<Grid item xs={6}>
											<Typography variant="h3" sx={{ margin: '10px 0 5px', fontWeight: '700', fontSize: '1.8rem' }}>
												Best of both worlds - <br />Private home + Resort Amenities
											</Typography>
											<Typography variant="body1">
		{`Located on Kings Castle Circle, you're just minutes away from Storey Lake's clubhouse featuring resort style pools, water slides, lazy-river, splash zone, fitness center, and more!`}
											</Typography>
										</Grid>
										<Grid item xs={6}>
											<Image aspect="4:3" url={droneImg} />
										</Grid>
									</Grid>
								</Paper>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<Box sx={{ padding: '25px' }}>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<video width="100%" controls={false} muted loop autoPlay style={{ borderRadius: '6px' }}>
													<source src={heroVideo} type="video/mp4" />
												</video>
											</Grid>
											<Grid item xs={12}>
												<Image aspect='3:4' url={kitchenImg} />
											</Grid>
											<Grid item xs={12}>
												<Image url={pizzaImg} />
											</Grid>
											<Grid item xs={12}>
												<Image url={shampooImg} />
											</Grid>
											<Grid item xs={12}>
												<Image url={primarySuiteBedroomImg} />
											</Grid>
											<Grid item xs={12}>
												<Image url={gamesImg} />
											</Grid>
											<Grid item xs={12}>
												<Image aspect="4:3" url={guestBedroomImg} />
											</Grid>
											<Grid item xs={12}>
												<Image url={twinBedroom2Img} />
											</Grid>
											<Grid item xs={12}>
												<Image aspect="4:3" url={jrSuiteBathroomImg} />
											</Grid>
											<Grid item xs={12}>
												<Image aspect="4:3" url={lazyRiverImg} />
											</Grid>
											<Grid item xs={12}>
												<Image aspect="4:3" url={gameRoomImg} />
											</Grid>
											<Grid item xs={12}>
												<Image aspect="4:3" url={coffee1} />
											</Grid>
											<Grid item xs={12}>
												<Image aspect="1:1" url={miniGolfImg} />
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={6}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<Image aspect='3:4' url={poolImg} />
											</Grid>
											<Grid item xs={12}>
												<Image url={livingImg} />
											</Grid>
											<Grid item xs={12}>
												<Image aspect="1:1" url={jrSuiteBedroomImg} />
											</Grid>
											<Grid item xs={12}>
												<Image aspect="3:4" url={primaryBathroomImg} />
											</Grid>
											<Grid item xs={12}>
												<Image url={deskImg} />
											</Grid>
											<Grid item xs={12}>
												<Image url={diningImg} />
											</Grid>
											<Grid item xs={12}>
												<Image url={bunkRoomImg} />
											</Grid>
											<Grid item xs={12}>
												<Image url={deskCloseUpImg} />
											</Grid>
											<Grid item xs={12}>
												<Image url={twinBedroom1Img} />
											</Grid>
											<Grid item xs={12}>
												<Image aspect="4:3" url={babyAmenitiesImg} />
											</Grid>
											<Grid item xs={12}>
												<Image aspect="4:3" url={coffee3} />
											</Grid>
											<Grid item xs={12}>
												<Image aspect="4:3" url={coffee2} />
											</Grid>
											<Grid item xs={12}>
												<Image aspect="4:3" url={laundryImg} />
											</Grid>
											<Grid item xs={12}>
												<Image aspect="1:1" alt="Storey Lake Resort Pool" url={resortPoolImg} />
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</ContentContainer>
		</>
  );
}

export default App;
